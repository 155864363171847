import React from "react"

import { withAccountRegisterForm } from "./withAccountRegisterForm"
import { Checkbox } from "../../../Styled/Checkbox/Checkbox"
import { AccountLoginToggle } from "../AccountLoginToggle"
import {
  Form,
  FormHeading,
  FormLabel,
  FormInput,
  FormInputWrapper,
  FormInputInner,
  FormInputButton,
  FormNote,
  FormButton,
  FormButtonWrapper,
  FormLinkWrapper,
  FormError,
} from "../../../Styled/Form"

export const AccountRegisterForm = withAccountRegisterForm(
  ({ data, errors, invalid, handleChange, handleFormToggle, handleSubmit, handleToggle, loading, locales, showPassword }) => (
    <Form onSubmit={handleSubmit}>
      <FormHeading>{locales?.registerTitle}</FormHeading>

      <FormInputWrapper>
        <FormInputInner first>
          <FormLabel>{locales?.firstName}</FormLabel>
          <FormInput name={`firstName`} value={data?.firstName} onChange={handleChange} type={`text`} collapsed />
        </FormInputInner>
        <FormInputInner last>
          <FormLabel>{locales?.lastName}</FormLabel>
          <FormInput name={`lastName`} value={data?.lastName} onChange={handleChange} type={`text`} collapsed />
        </FormInputInner>
      </FormInputWrapper>

      <FormLabel>{locales?.emailAddress}</FormLabel>
      <FormInput name={`email`} value={data?.email} onChange={handleChange} type={`email`} />
      <FormLabel>{locales?.password}</FormLabel>
      <FormInputWrapper>
        <FormInput collapsed={`true`} name={`password`} value={data?.password} onChange={handleChange} type={showPassword ? `text` : `password`} />
        <FormInputButton onClick={handleToggle} type={`button`}>
          {showPassword ? locales?.passwordHide : locales?.passwordShow}
        </FormInputButton>
      </FormInputWrapper>
      <FormLinkWrapper>
        <Checkbox name={`acceptsMarketing`} checked={data?.acceptsMarketing} externalState onChange={handleChange}>
          {locales?.subscribe}
        </Checkbox>
      </FormLinkWrapper>
      <FormButtonWrapper>
        <FormButton disabled={invalid || loading} type={`submit`}>
          {locales?.registerButton}
        </FormButton>

        {handleFormToggle && <AccountLoginToggle handleToggle={handleFormToggle} />}

        <FormNote>{locales?.terms}</FormNote>

        {errors?.length > 0 && errors?.map(({ code, message }) => <FormError key={code}>{message}</FormError>)}
      </FormButtonWrapper>
    </Form>
  )
)
