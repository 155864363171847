import React, { useEffect, useState } from "react"

import { useCheckout, useCheckoutContext } from "../../../../hooks/useCheckout"
import { useCustomerRegister, useCustomerContext, useCustomerDetails } from "../../../../hooks/useCustomer"
import { useLanguage } from "../../../../hooks/useLanguage"
import { useFunctions } from "../../../../hooks/useFunctions"

export const withAccountRegisterForm = Component => ({ name = `AccountRegisterForm`, handleFormToggle, isCheckout }) => {
  const { createCustomer, data, setData, loading, errors } = useCustomerRegister()
  const { customer } = useCustomerContext()
  const { customerAccessToken } = useCustomerDetails()
  const { checkout } = useCheckoutContext()
  const { updateCustomer, checkoutUrl } = useCheckout()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { checkoutMultipass } = useFunctions()

  const handleCheckout = async () => {
    if (checkout?.email !== customer?.email) {
      setCheckoutLoading(true)
      await updateCustomer(customerAccessToken)
      setCheckoutLoading(false)
    }

    // window.location = checkoutUrl
    await checkoutMultipass(customer?.email, checkout.id, checkoutUrl)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const filteredData = Object.entries(data).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
    await createCustomer(
      {
        ...filteredData,
      },
      isCheckout
    )
  }

  const handleToggle = () => {
    setShowPassword(prevState => !prevState)
  }

  const handleChange = ({ name, target, type }) => {
    setData(prevState => ({
      ...prevState,
      [target?.name || name]: type === "checkbox" ? !data[name] : target?.value,
    }))
  }

  const invalid = !data?.email || !data?.password || !data?.firstName || !data?.lastName

  const locales = {
    ...useLanguage(`account`),
    ...useLanguage(`form`),
  }

  useEffect(() => {
    if (isCheckout && checkout && customer?.email && customerAccessToken) {
      handleCheckout()
    }
  }, [customer, customerAccessToken])

  Component.displayName = name
  return (
    <Component
      data={data}
      errors={errors}
      invalid={invalid}
      handleChange={handleChange}
      handleFormToggle={handleFormToggle}
      handleSubmit={handleSubmit}
      handleToggle={handleToggle}
      loading={loading || checkoutLoading}
      locales={locales}
      showPassword={showPassword}
    />
  )
}
